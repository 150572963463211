import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  datoApi,
  defaultSettings,
  getApiSettingsFromHost,
} from '@hultafors/hultafors/api';
import {
  GlobalFields,
  PageNotFoundFragment,
  PageProps,
} from '@hultafors/hultafors/types';

const NotFoundPageContent = dynamic(() =>
  import('@hultafors/hultafors/components').then(
    (mod) => mod.NotFoundPageContent,
  ),
);

export interface PageNotFoundProps extends PageProps {
  content: PageNotFoundFragment;
}

export const getStaticProps: GetStaticProps<PageNotFoundProps> = async ({
  locale,
}) => {
  try {
    // Fetch default locale data
    const settings = getApiSettingsFromHost(defaultSettings.hostname);
    const dato = datoApi(locale);

    // Fetch page content
    const {
      data: { pageNotFound, ...globalFields },
    } = await dato.getPageNotFound();

    const props: PageNotFoundProps = {
      content: pageNotFound || null,
      ...(globalFields as GlobalFields),
      settings,
    };
    return { props };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

const Custom404: NextPage<PageNotFoundProps> = ({ content, settings }) => {
  return <NotFoundPageContent content={content} settings={settings} />;
};

export default Custom404;
